import {ActionMenu} from "./ActionMenu";
import { ThemeIcon } from '@mantine/core';
import { useNavigate, Link, useLocation } from "react-router-dom"
import { Table as MTable } from "@mantine/core"
import { IconAlertCircle, IconAlertCircleFilled, IconBellX, IconCheck, IconClockQuestion, IconDotsVertical, IconExclamationCircle, IconExclamationCircleFilled, IconHandFinger, IconMail, IconMailbox, IconPencil, IconPhoto } from '@tabler/icons-react';

export interface TableProps {
  data: object[]
  perPaymentKey: string
}
/** Table rendering component */
export function Table({ data, perPaymentKey }: TableProps) {
  const keys = ['intention_status', 'customer_organization_name', 'daysUntilDueDate', 'new_msg', 'external_id', 'amount', 'currency_type', 'due_date', 'daysSinceLastReceived'];

  const rowStyle = "text-midnight-700 font-normal text-xs cursor-pointer"
  const headStyle = "text-midnight-900 font-medium text-xs"

  const navigate = useNavigate();
  const handlePaymentClick = (payment) => {
    navigate('/email', {state: payment});
  };
  const tableColumnNames = {'customer_organization_name':'Client', 'external_id':'Invoice #', 'amount':'Amount', 'due_date':'Due date', 'customer_email':'Email Correspondence', 'currency_type':'Currency', 'intention_status':'Status', 'new_msg':'Email draft', 'daysUntilDueDate':'Overdue?', 'daysSinceLastReceived': '# days since their last email'}
  
  const headers = keys.map((key, index) => (
    <MTable.Th key={index} className={headStyle}>{tableColumnNames[key]}</MTable.Th>))

  const rows = data.map((element, index) => (
    <MTable.Tr key={element[perPaymentKey]}
      className="border-neutral-100 hover:bg-lavender-50">

      {/* Display status icon (first, set its color) */}
      <MTable.Td className={rowStyle} onClick={() => handlePaymentClick(element)} > 
        <ThemeIcon color={
          element['intention_status'] === 'Unresolved'? 
            'yellow': 
          element['intention_status'] === 'Approved'? 
            'green' :
          element['intention_status'] === 'Needs_attention'? 
            'red': 'orange'} variant='transparent'>
          {
            element['intention_status'] === 'Unresolved'? 
              <IconHandFinger/> : 
            element['intention_status'] === 'Approved'? 
              <IconCheck/> :
              element['intention_status'] === 'Needs_attention'? 
              <IconAlertCircle/>:
              <IconClockQuestion/>
          }
        </ThemeIcon>
      </MTable.Td>
      
        {/* Then, customer/client name*/}
      <MTable.Td className={rowStyle} onClick={() => handlePaymentClick(element)} > 
        {/* Limit the length of the name to at most 60 characters */}
            {element['customer_organization_name'].slice(0, 60)}
      </MTable.Td>

       {/* Then, "Overdue?" */}
       <MTable.Td className={rowStyle} onClick={() => handlePaymentClick(element)} > 
        
        <ThemeIcon color={'red'} variant='transparent'>
          {
            element['daysUntilDueDate'] > 1 ?
            <IconAlertCircleFilled/> : <></>
          }
        </ThemeIcon>
      </MTable.Td>


      {/* Then, Communciation Status */}
      <MTable.Td className={rowStyle} onClick={() => handlePaymentClick(element)} > 
        <ThemeIcon color={'black'} variant='transparent'>
          {
            element['message_draft_url'] && element['message_draft_url'] != '' ? 
            <IconPencil/> : <></>
              
          }
        </ThemeIcon>
      </MTable.Td>

      {keys.slice(4, -1).map((key, index) => (
        <MTable.Td key={index} className={rowStyle} onClick={() => handlePaymentClick(element)}>{element[key]}</MTable.Td>
      ))}
      {/* Days since their last email */}
      <MTable.Td key={index} className={rowStyle} onClick={() => handlePaymentClick(element)}>
        {element['daysSinceLastReceived'] == Infinity ? 
        '-' :
        element['daysSinceLastReceived']
        }
        </MTable.Td>

      {/* When restoring action menu, change the way the slice (shown above) happens. You can just replace the above, with the commented out slice below */}

      {/* {keys.slice(2, -2).map((key, index) => (
        <MTable.Td key={index} className={rowStyle} onClick={() => handlePaymentClick(element)}>{element[key]}</MTable.Td>
      ))} */}

      {/* Action menu */}
      {/* <MTable.Td key={keys.length} className={rowStyle}>
        <ActionMenu paymentId='3'/>
      </MTable.Td> */}
      
    </MTable.Tr>
  ))


  return (
    <div className="border-[1px] border-neutral-100 rounded-lg overflow-hidden">
      <MTable.ScrollContainer minWidth={800}>
        <MTable horizontalSpacing="lg" verticalSpacing="sm">
          <MTable.Thead className="bg-lavender-100">
            <MTable.Tr>{headers}</MTable.Tr>
          </MTable.Thead>
          <MTable.Tbody>{rows}</MTable.Tbody>
        </MTable>
      </MTable.ScrollContainer>
    </div>
  )
}
