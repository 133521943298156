import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { localLogOut } from "./slices/authSlice"
import { RootState } from "./store"

const baseQuery = fetchBaseQuery({
  /* The vanilla base query that embeds the authentication credentials (taken from the redux store)
  into all queries sent to the server. */
  // baseUrl: "http://localhost:8000/api/v1/",
  baseUrl: "https://budy-4162a8627463.herokuapp.com/api/v1/",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    const uid = (getState() as RootState).auth.uid
    if (token && uid) {
      headers.set("authorization", `Bearer ${token}`)
      headers.set("x-user-id", uid)
    }
    return headers
  },
})

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: any,
  extraOptions: object,
) => {
  // Queries sent to the server will use this query function (which wraps the vanilla base query).
  const result: any = await baseQuery(args, api, extraOptions)
  /* If the vanilla query fails because the user is unauthorized, log the user out and ask them to reconnect.
  This can happen because of two reasons:
  1. The user's token has expired and it can't be refreshed automatically. So we need him to log in again.
  2. The frontend is somehow broken, or was tampered with.
  */
  if (result?.error?.status === 403) {
    console.log("redirecting user to login page for (fresh?) authentication")
    // TODO: Add a notification explaining why the user is being logged out, and ideally pass the current page
    // to the login page so that it can redirect the user there.
    api.dispatch(localLogOut())
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Payments', 'Payment', 'Email'],
  endpoints: (builder) => ({}),
})
