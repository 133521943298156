import React from "react"
import {useState, useEffect} from "react"
import { Outlet, useLocation } from "react-router-dom"
import { AppShell, Burger, Modal, ThemeIcon, Image, Tooltip } from "@mantine/core"
import { Navbar } from "./Navbar"
import {ChartLine,Invoice,UserCircleGear,Faders,UsersFour} from "@phosphor-icons/react"
import { ActionIcon } from '@mantine/core';
import { IconLogout, IconQuestionMark } from '@tabler/icons-react';
import { useDisclosure } from "@mantine/hooks"
import { localLogOut } from "../store/slices/authSlice"
import { useDispatch } from "react-redux"


const navbarData = [
  { icon: Invoice, label: "Invoices", url: "/", id:1 },
  { icon: UsersFour, label: "Clients", url: "/", id:2 },
  // { icon: ChartLine, label: "Analytics", url: "/", id:3 },
  // { icon: UserCircleGear, label: "Admin", url: "/", id:4 },  
  // { icon: Faders, label: "Settings", url: "/", id:5 },  
]

export default function AppSkeleton() {
  // const [opened, setOpened] = useState(false);
  const [toggleSelected, { toggle }] = useDisclosure();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [opened, setOpened] = useState(false);
  const [helperContent, setHelperContent] = useState('/');
  const dispatch = useDispatch()

  useEffect(() => {
    const handleLocationChange = () => setCurrentPage(location.pathname);
    setHelperContent(location.pathname);
    // Add event listener for location changes
    window.addEventListener('popstate', handleLocationChange);
    // Cleanup function to remove listener on component unmount
    return () => window.removeEventListener('popstate', handleLocationChange);
  }, [location]); // Dependency array for useEffect

  const logOut = (() => {
    dispatch(localLogOut())
  })

 return (
      <AppShell navbar={{ width: 96, breakpoint: "sm", collapsed: { mobile: !toggleSelected }}} 
              header={{ height: 34}}>
      <AppShell.Header>
        <div className='flex flex-row w-full'>
        <Burger opened={toggleSelected} onClick={toggle} hiddenFrom="sm" size="sm" p={'1rem'}/>
          <div className='pt-0.7 pl-3.5'>
            {/* <IconBat size={'2.5rem'}/> */}
            <Image radius="md" h="30" w='auto'
            src="/logo.jpg"/>
            </div>
            <div className='flex flex-row w-10 ml-auto justify-end order p-0.5'>
              <Tooltip label="Help">
                  <ActionIcon aria-label="Settings" color='#DCDCDC'>
                    <ThemeIcon  variant="light" color='black'>
                      <IconQuestionMark onClick={() => setOpened(true)} style={{ width: '120%', height: '120%' }}  stroke={2} color='black'/>
                    </ThemeIcon>
                  </ActionIcon>
                </Tooltip>

                <Tooltip label="Log out">
                  <ActionIcon aria-label="Settings" color='#DCDCDC' ml={10}>
                    <ThemeIcon  variant="light" color='black'>
                      <IconLogout onClick={logOut} style={{ width: '120%', height: '120%' }}  stroke={2} color='black'/>
                    </ThemeIcon>
                  </ActionIcon>
                </Tooltip>
            </div>
            
          </div>
        
      </AppShell.Header>

      <AppShell.Navbar>
        <div className="flex flex-col items-center justify-start mt-12">
          <Navbar data={navbarData} />
        </div>
      </AppShell.Navbar>
      <AppShell.Main >
      <Modal opened={opened}
        onClose={() => setOpened(false)}
        title="What this page is showing:">
                {helperContent}
      </Modal>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  )
}
