import { Text } from "@mantine/core"
import { Icon } from "@phosphor-icons/react"

export interface CardProps {
  key: number
  /** Card heading text */
  heading: string
  /** Card description placed under heading */
  description: string
  /** Card subtext placed at the bottom */
  subText: string
  /** Icon placed in top right of card */
  Icon: Icon
  /** Color scheme for the card */
  color: "aqua" | "lime" | "apricot" | "lavender" | "yellow" | "orange" | "red",
  onClickFunc: () => void;
  isSelected: boolean;
}

/** Card for presenting data and an icon */
export const Card = ({
  heading,
  description,
  subText,
  Icon,
  color,
  onClickFunc,
  isSelected
}: CardProps) => {
  let borderColor, subtextColor, gradientColor
  switch (color) {
    case "lime":
      borderColor = "border-lime-500 bg-lime-50 text-lime-700/80"
      subtextColor = "text-lime-700/60"
      gradientColor = "from-lime-500/30 border-lime-700"
      break
    case "apricot":
      borderColor = "border-apricot-500 bg-apricot-50 text-apricot-700/80"
      subtextColor = "text-apricot-700/60"
      gradientColor = "from-apricot-500/30 border-apricot-700"
      break
    case "lavender":
      borderColor = "border-lavender-500 bg-lavender-50 text-lavender-700/80"
      subtextColor = "text-lavender-900/60"
      gradientColor = "from-lavender-500/30 border-lavender-700"
      break
    case "aqua":
      borderColor = "border-aqua-500 bg-aqua-50 text-aqua-700/80"
      subtextColor = "text-aqua-700/60"
      gradientColor = "from-aqua-500/30 border-aqua-700"
      break
    case "yellow":
      borderColor = "border-yellow-600 bg-yellow-50 text-yellow-700/80"
      subtextColor = "text-black-700/60"
      gradientColor = "from-yellow-500/30 border-yellow-700"
      break
    case "orange":
      borderColor = "border-orange-600 bg-orange-50 text-orange-700/80"
      subtextColor = "text-black-700/60"
      gradientColor = "from-orange-500/30 border-orange-700"
      break
    case "red":
      borderColor = "border-red-600 bg-red-50 text-red-700/80"
      subtextColor = "text-black-700/60"
      gradientColor = "from-red-500/30 border-red-700"
      break
  }
  let icon = <Icon weight="light" size={64} />
  let classNameDependingOnSelected = ''
  if (isSelected) {
    classNameDependingOnSelected = "border-b-4 " + borderColor;
  }
  
  return (
    <>
    <div className={classNameDependingOnSelected} style={{ height: "10.5rem"}} onClick={onClickFunc}>
      <div
        className={
          "flex flex-col w-64 h-40 rounded-lg p-4 gap-9 border hover:border-b-2 hover:-translate-y-1 hover:shadow-lg transition transform " +
          borderColor
        }
        >
        <div className="flex flex-row flex-auto">
          <div className="flex flex-col flex-auto gap-1 justify-around">
            <Text size="lg" className="font-bold">
              {heading}
            </Text>
            <Text size="sm" className={subtextColor}>
              {description}
            </Text>
          </div>
          {icon}
        </div>
        <div
          className={
            "bg-gradient-to-r to-40% rounded-md border-l px-2 py-0.5 " +
            gradientColor
          }
        >
          <Text size="sm">{subText}</Text>
        </div>
      </div>
    </div>
    </>
  )
}
