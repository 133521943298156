import { createSlice } from "@reduxjs/toolkit"
import { firebaseAuth } from "../../../firebase-config.js"

export interface AuthResponse {
  user: string | null
  token: boolean | null
  roles: string[] | null
  uid: string | null
}

const initialState: AuthResponse = {
  user: null,
  token: null,
  roles: null,
  uid: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token, roles, uid } = action.payload
      state.user = user
      state.token = token
      state.roles = roles
      state.uid = uid
    },
    localLogOut: (state) => {
      state.user = null
      state.token = null
      state.roles = null
      state.uid = null
      firebaseAuth.signOut().then(() => {
        location.reload()
      })
    },
  },
})

export const { setCredentials, localLogOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: { auth: { user: string | null } }) =>
  state.auth.user
export const selectCurrentToken = (state: {
  auth: { token: boolean | null }
}) => state.auth.token
export const selectCurrentRoles = (state: {
  auth: { roles: string[] | null }
}) => state.auth.roles
