import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./baseQueries"
import authReducer from "./slices/authSlice"
import paymentsReducer from './slices/paymentSlice';
import emailsReducer from "./slices/emailSlice";
import generalDataReducer from "./slices/generalDataSlice";
import clientReducer from "./slices/clientSlice";

export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    payments: paymentsReducer,
    emails: emailsReducer,
    generalData: generalDataReducer,
    clients: clientReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>;
