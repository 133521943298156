import { Outlet, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { selectCurrentToken, setCredentials } from "../store/slices/authSlice"
import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth"
import { firebaseAuth } from "../../firebase-config.js"

const PersistLogin = () => {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // TODO: This should be removed. The roles should be determined by the server, not set locally.
  const roles = ["user"]

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (loggedInuser) => {
      if (loggedInuser) {
        const uid = loggedInuser.uid
        const user = loggedInuser.displayName
        console.log("verified", loggedInuser.emailVerified, uid)
        if (!loggedInuser.emailVerified) {
          await sendEmailVerification(loggedInuser)
            .then(() => {
              navigate("/emailVerification")
            })
            .catch((error) => {
              navigate("/emailVerification")
            })
        } else {
          // since we aren't passing `true` as an argument to getIdToken, it won't request that the token be refreshed.
          const token = await loggedInuser.getIdToken()
          console.log(uid, "persist page login")
          dispatch(setCredentials({ user, roles, token, uid }))
        }
      } else {
        console.log("user isn't logged in")
      }
      setIsPageLoading(false)
    })
  }, [dispatch])

  return <>{isPageLoading ? <p>Loading...</p> : <Outlet />}</>
}

export default PersistLogin
