import { Text } from "@mantine/core"
import { Icon } from "@phosphor-icons/react"

export interface MinimalCardProps {
  /** Card heading text */
  heading: string
  /** Card description placed under heading */
  description: string
  /** Icon placed in top right of card */
  Icon: Icon
  key: number
}

/** Card for presenting data and an icon */
export const MinimalCard = ({
  heading,
  description,
  Icon,
  key
}: MinimalCardProps) => {
  let icon = <Icon weight="thin" size={32} />

  return (
    <div className="flex items-start gap-6">
      <div className="p-1 border border-midnight-900 rounded-md h-min">
        {icon}
      </div>
      <div className="flex flex-col flex-auto gap-2 justify-around">
        <Text size="sm" className="text-midnight-900">
          {heading}
        </Text>
        <Text size="lg" className="font-bold text-midnight-900">
          {description}
        </Text>
      </div>
    </div>
  )
}

export interface MinimalCardListProps {
  cardProps: MinimalCardProps[]
}

export const MinimalCardList = ({ cardProps }: MinimalCardListProps) => {
  const cards = cardProps.map((props) => (
    <div key={props.key} className="px-11 ">{MinimalCard(props)}</div>
  ))
  return (
    // <div className="flex flex-row justify-between w-full items-stretch divide-solid divide-midnight-900 divide-x">
    <div className="flex flex-row justify-between w-full items-stretch">
      {cards}
    </div>
  )
}
