import { useLocation, Navigate, Outlet } from "react-router-dom"
import {selectCurrentRoles,selectCurrentToken} from "../store/slices/authSlice"
import { useSelector } from "react-redux"
import React from "react"

const RequireAuth = ({ allowedRoles }: { allowedRoles?: string[] }) => {
  const roles = useSelector(selectCurrentRoles)
  const accessToken = useSelector(selectCurrentToken)
  const location = useLocation()

  return roles?.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : accessToken ? ( // The unauthorized page shouldn't be reachable unless some frontend bug/tampering occurred.
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireAuth
