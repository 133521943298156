import { useNavigate, Link, useLocation } from "react-router-dom"
import { useLoginMutation, useSignupMutation } from "../store/slices/apiSliceEndpoints"
import { setCredentials } from "../store/slices/authSlice"
import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import { useForm } from "@mantine/form"
import { IconLock, IconAt } from "@tabler/icons-react"
import {
  TextInput,
  PasswordInput,
  Group,
  Checkbox,
  Button,
  Paper,
  Text,
  LoadingOverlay,
  Anchor,
  Center,
  Container,
  Stack,
  Divider,
} from "@mantine/core"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  sendEmailVerification,
  inMemoryPersistence,
} from "firebase/auth"
import { firebaseAuth } from "../../firebase-config.js"
import {
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth"

export default function AuthenticationForm() {
  const [formType, setFormType] = useState<"signup" | "login">("login")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const location = useLocation()
  const from = location.state?.from || "/"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [persist, setPersist] = useState(true)
  // TODO: This should be removed. The roles should be determined by the server, not set locally.
  const roles = ["user"]

  /*
  ===============
  SSO related code
  ===============
  */

  const googleProvider = new GoogleAuthProvider()
  // All read/write operations except immediate, permanent deletion of threads and messages, bypassing Trash.
  googleProvider.addScope("https://www.googleapis.com/auth/gmail.modify")
  googleProvider.setCustomParameters({
    access_type: "offline",
    prompt: "consent",
  })

  // Scope is defined in Azure (similar to what was described above for gmail).
  const msftProvider = new OAuthProvider("microsoft.com")
  msftProvider.addScope("offline_access")

  // Sign in a user via Google SSO.
  const googleSignIn = () => {
    signInWithPopup(firebaseAuth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.idToken
        const user = result.user
        if (persist) {
          // https://firebase.google.com/docs/auth/web/auth-state-persistence#supported_types_of_auth_state_persistence
          setPersistence(firebaseAuth, browserLocalPersistence)
        } else {
          setPersistence(firebaseAuth, inMemoryPersistence)
        }
        dispatch(
          setCredentials({
            user: user.displayName,
            roles,
            token,
            // This is how the backend will identify the user and his/her token.
            uid: user.uid,
          }),
        )
        setLoading(false)
        if (from !== "/") {
          navigate(from, { replace: true })
        }
      })
      .catch((error) => {})
  }

  // Sign in a user via Microsoft SSO.
  const msftSignIn = async (internalMsftToken, token, uid) => {
    try {
      const response = await fetch("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${internalMsftToken}`,
        },
      })
      const data = await response.json()
      const { displayName, email } = data
      if (persist) {
        // https://firebase.google.com/docs/auth/web/auth-state-persistence#supported_types_of_auth_state_persistence
        setPersistence(firebaseAuth, browserLocalPersistence)
      } else {
        setPersistence(firebaseAuth, inMemoryPersistence)
      }
      dispatch(setCredentials({ user: displayName, roles, token, uid }))
      setLoading(false)
      if (from !== "/") {
        navigate(from, { replace: true })
      }
    } catch (error) {
      console.log("msft sign in error", error)
    }
  }

  const msftSignInWrapper = async () => {
    signInWithPopup(firebaseAuth, msftProvider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result)
        const internalMsftToken = credential.accessToken
        const token = credential.idToken
        msftSignIn(internalMsftToken, token, result.user.uid)
      })
      .catch((error) => {})
  }

  /*
  ===============
  Logic and functions that support the signup/login flow.
  ===============
  */

  const toggleFormType = () => {
    setFormType((current) => (current === "signup" ? "login" : "signup"))
    setError(null)
  }

  const passwordErrorMsg =
    "At least 8 characters, including at least one lowercase letter, one uppercase letter, and one of the following: !@#$%^&*()"
  // Mantine functionality for automatically validating the form's data.
  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsOfService: false,
    },
    validate: {
      username: (value) =>
        formType === "signup" && value.length == 0
          ? "At least 1 character"
          : null,
      email: (value) =>
        formType === "signup" &&
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value)
          ? "Invalid email"
          : null,
      password: (value) =>
        formType === "signup" &&
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/.test(value)
          ? passwordErrorMsg
          : null,
      confirmPassword: (value, values) =>
        formType === "signup" && value != values.password
          ? "Passwords are not the same"
          : null,
      termsOfService: (value) =>
        formType === "signup" && value === false
          ? "You must agree to the terms of service to continue"
          : null,
    },
    validateInputOnBlur: true,
  })

  // Process the signup/login request, after the user submits the form.
  const handleSubmit = async (values) => {
    setLoading(true)
    setError(null)
    if (formType === "signup") {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          firebaseAuth,
          values.email,
          values.password,
        )
        await updateProfile(userCredential.user, {
          displayName: values.username,
        })
        sendEmailVerification(userCredential.user)
          .then(() => {
            navigate("/emailVerification")
          })
          .catch((error) =>
            setError("An unexpected error occurred. Please retry."),
          )
      } catch (err) {
        setLoading(false)
        if (err.message == "Firebase: Error (auth/email-already-in-use).") {
          setError("Email already in use")
        }
      }
    } else {
      try {
        const userCredential = await signInWithEmailAndPassword(
          firebaseAuth,
          values.email,
          values.password,
        )
        // passing true as an argument forces a refresh of the token
        const token = await userCredential.user.getIdToken(true)
        if (!userCredential.user.emailVerified) {
          await sendEmailVerification(userCredential.user)
            .then(() => {
              navigate("/emailVerification")
            })
            .catch((error) => {
              navigate("/emailVerification")
            })
        } else {
          if (persist) {
            // https://firebase.google.com/docs/auth/web/auth-state-persistence#supported_types_of_auth_state_persistence
            setPersistence(firebaseAuth, browserLocalPersistence)
          } else {
            setPersistence(firebaseAuth, inMemoryPersistence)
          }
          dispatch(
            setCredentials({
              user: userCredential.user.displayName,
              roles,
              token,
              uid: userCredential.user.uid,
            }),
          )
          setLoading(false)
          if (from !== "/") {
            navigate(from, { replace: true })
          }
        }
      } catch (err) {
        setLoading(false)
        if (err.message == "Firebase: Error (auth/invalid-credential).") {
          setError("Invalid credentials")
        }
      }
    }
  }

  return (
    <Container w={330} mt={15} ta="center">
      <Paper
        style={{
          position: "relative",
          // backgroundColor: 'var(--mantine-color-gray-very-light)',
          backgroundColor: "transparent",
        }}
        withBorder
        p={10}
      >
        <Anchor
          component="button"
          type="button"
          c="dimmed"
          onClick={toggleFormType}
          size="m"
          mb={10}
          underline="always"
        >
          {formType === "signup"
            ? "Have an account? Login here"
            : "Don't have an account? Sign up here"}
        </Anchor>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <LoadingOverlay visible={loading} />
          {formType === "signup" && (
            <Group grow>
              <TextInput
                required
                data-autofocus
                placeholder="Username"
                label="Username"
                {...form.getInputProps("username")}
              />
            </Group>
          )}

          <TextInput
            mt="md"
            required
            placeholder="Your email"
            label="Email"
            leftSection={<IconAt size={16} stroke={1.5} />}
            {...form.getInputProps("email")}
          />

          <PasswordInput
            mt="md"
            required
            placeholder="Password"
            label="Password"
            leftSection={<IconLock size={16} stroke={1.5} />}
            {...form.getInputProps("password")}
          />

          {formType === "signup" && (
            <PasswordInput
              mt="md"
              required
              label="Confirm Password"
              placeholder="Confirm password"
              leftSection={<IconLock size={16} stroke={1.5} />}
              {...form.getInputProps("confirmPassword")}
            />
          )}

          <Text ta="left" size="sm" mt={8} c="dimmed">
            <Link to={"/forgotPassword"}>Forgot your password?</Link>
          </Text>

          <Checkbox
            checked={persist}
            onChange={() => setPersist((persist) => !persist)}
            mt="lg"
            label="Trust this device"
          />

          {formType === "signup" && (
            <Checkbox
              mt="sm"
              label={
                <>
                  {" "}
                  I agree to the{" "}
                  <Anchor href="https://mantine.dev" target="_blank" inherit>
                    {" "}
                    terms and conditions{" "}
                  </Anchor>{" "}
                </>
              }
              {...form.getInputProps("termsOfService", { type: "checkbox" })}
            />
          )}

          {error && (
            <Text c="red" size="sm" mt="sm">
              {error}
            </Text>
          )}

          <Group mt="sm">
            <Button color="blue" variant="outline" type="submit">
              {formType === "signup" ? "Sign up" : "Login"}
            </Button>
          </Group>
        </form>

        <Stack mt="xs">
          <Divider my="m" label="OR" labelPosition="center" />
          <Container>
            <Button onClick={googleSignIn} variant="outline" w={230}>
              <span>Continue with Google &nbsp;</span>
              <img style={{ height: "95%" }} src="/google.svg" alt="google" />
            </Button>
          </Container>

          <Container>
            <Button onClick={msftSignInWrapper} variant="outline" w={230}>
              <span>Continue with Microsoft &nbsp;</span>
              <img
                style={{ height: "80%" }}
                src="/microsoft.svg"
                alt="microsoft"
              />
            </Button>
          </Container>

          <Checkbox
            checked={persist}
            onChange={() => setPersist((persist) => !persist)}
            mt="lg"
            label="Trust this device"
          />
        </Stack>
      </Paper>
    </Container>
  )
}
