import { RootState } from '../store/store';
import {useSendEmailMutation} from '../store/slices/apiSliceEndpoints'
import { FileButton, Group, VisuallyHidden, Notification, rem, Loader   } from '@mantine/core';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import React from 'react'
import EmailEditor from './EmailEditor'
import { useLocation } from 'react-router-dom';
import { Text, TextInput, FileInput, Button, Timeline, Spoiler, Divider  } from '@mantine/core';
import { useState } from "react"
import {CalendarDots, Invoice, HandCoins, ArrowLeft} from "@phosphor-icons/react"
import { MinimalCardList } from './MinimalCard';
import { IconAdjustments, IconBrandWhatsapp, IconCalendarClock, IconCheck, IconGrid3x3, IconMailbox, IconMessageChatbot, IconPhoto, IconSend, IconUpload } from "@tabler/icons-react";
import { selectEmailById } from '../store/slices/emailSlice'
import { selectClientById } from '../store/slices/clientSlice'
import { useSelector } from 'react-redux';


const minimalCardData = [
    {
      heading: "Payment Due Date",
      description: "",
      Icon: CalendarDots,
      key:1,
    },
    {
      heading: "Invoice Amount",
      description: "",
      Icon: Invoice,
      key:2,
    },
    {
        heading: "Invoice ID",
        description: "",
        Icon: IconGrid3x3,
        key:3,
      },
    // {
    //   heading: "Client's balance",
    //   description: "?",
    //   Icon: HandCoins,
    //   key:4,
    // },
  ]

type Props = {}

export default function EmailCenter(props: Props) {
    const location = useLocation();
    const paymentData = location.state || {};
    // TODO: Change this, it should be taken from paymentData.message_draft_url rather than hardcoded
    const emailDraft = useSelector((state: RootState) => selectEmailById(state, paymentData.message_draft_url));
    
    const [subject, setSubject] = useState(emailDraft?.subject || '');
    const [emailToAddress, setEmailToAddress] = useState(emailDraft?.to || '');
    const [emailCcAddress, setEmailCcAddress] = useState(emailDraft?.cc || '');
    const [emailBccAddress, setEmailBccAddress] = useState(emailDraft?.bcc || '');
    const [attachments, setAttachments] = useState<File[]>([]);
    const [emailDraftModificationPrompt, setEmailDraftModificationPrompt] = useState('');
    const [file, setFile] = useState<File | null>(null);  
    const [toggleCcBcc, setToggleCcBcc] = useState(false);
    let ccBccVisibility = false;
    const navigate = useNavigate();
    const client = useSelector((state: RootState) => selectClientById(state, paymentData['customer_organization_id']));
    const [sendEmail, { isLoading, isSuccess, isError }] = useSendEmailMutation();

    
    useEffect(() => {
        if (emailDraft) {
          setSubject(emailDraft.subject || '');
          setEmailToAddress(emailDraft.to || '');
          setEmailCcAddress(emailDraft.cc || '');
          setEmailBccAddress(emailDraft.bcc || '');
        }
      }, [emailDraft]);

    const handleSubmit = async (e) => {
        const emailData = {
            text: emailDraft?.text,
            subject: emailDraft?.subject,
            to: emailDraft?.to,
            cc: emailDraft?.cc,
            bcc: emailDraft?.bcc,
            threadId: emailDraft?.thread_id,
          }
        e.preventDefault();
        try {
          const response = await sendEmail({emailData, draftUrl:paymentData.message_draft_url, attachments}).unwrap();
          if (response == 'success') {
           navigate('/', {state: {emailStatus:'email sent successfully'}})
          }
        } catch (error) {
          console.error('Failed to send email', error, emailData);
        }
      };
    

    if (paymentData) {
        minimalCardData[0].description = paymentData['due_date']
        const amount = paymentData['amount']
        let amountWithCurrency = '';
        if (paymentData['currency_type'] == 'USD') {
            amountWithCurrency = '$ ' + amount
        } else {
            amountWithCurrency = amount + ' ' + paymentData['currency_type']
        }
        minimalCardData[1].description = amountWithCurrency
        minimalCardData[2].description = paymentData.external_id
    }

    // if (client) {
    //     minimalCardData[2].description = client.balance
    // }


    toggleCcBcc? ccBccVisibility = true: ccBccVisibility = false;

    const goBack = () => {
        navigate(location.state?.from || '/');
    };
    const hoverClassButton = 'hover:bg-midnight-200';
    const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

    
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col p-4'>
                <div className='flex flex-row'>
                    <div className='w-1/12 mb-1 cursor-pointer' onClick={goBack}>
                        <ArrowLeft weight="thin" size={32} />
                    </div>
                    <div className='w-8/12 mb-1 ml-[10%]'>
                        <MinimalCardList cardProps={minimalCardData}/>
                    </div>
                </div>
                <div className='flex flex-row justify-center my-10'>
                    {isLoading ? 
                        <Loader size={20} color="blue" /> :
                        <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} mx='20' pl='10' size={"l"} leftSection={<IconSend/>} onClick={handleSubmit}>Send</Button> 
                    }
                    {/* <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} mr='20' pl='10' size={"l"} leftSection={<IconMessageChatbot/>}>Change</Button>  */}
                    {/* <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} mr='20' pl='10' size={"l"} leftSection={<IconCalendarClock />}>Follow Up</Button> */}
                    {/* <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} pl='10' size={"l"} leftSection={<IconBrandWhatsapp />}>WhatsApp</Button> */}
                </div>

                <div className='mb-5'>
                <TextInput styles={{input: {color:'gray'}}} value={emailDraftModificationPrompt} placeholder="Ask the AI to change the email"
                        onChange={(event) => setEmailDraftModificationPrompt(event.currentTarget.value)}/>
                </div>
                <div className='flex space-x-2 items-end'>
                    <TextInput required styles={{input: {color:'gray'}}} value={emailToAddress} description='To'
                    onChange={(event) => setEmailToAddress(event.currentTarget.value)} w={"16rem"}/>
                    <FileInput clearable multiple value={attachments} onChange={setAttachments} description="Attachments" placeholder="Upload here"/>
                    <TextInput w={"40rem"}styles={{input: {color:'gray'}}} value={subject} description='Subject'
                    onChange={(event) => setSubject(event.currentTarget.value)}/>
                    <div className='cursor-pointer' onClick={()=>setToggleCcBcc(!toggleCcBcc)}>
                        <Text size='xs' td='underline' c='blue'>Cc, Bcc</Text>
                    </div>
                </div>
                {toggleCcBcc && 
                    <div className='flex space-x-2 items-end mt-2' >
                        <TextInput styles={{input: {color:'gray'}}} value={emailCcAddress} description="Cc"
                            onChange={(event) => setEmailCcAddress(event.currentTarget.value)} w={"16rem"}/>
                        <TextInput styles={{input: {color:'gray'}}} value={emailBccAddress} description="Bcc"
                            onChange={(event) => setEmailBccAddress(event.currentTarget.value)} w={"16rem"}/>
                    </div>
                }
            </div>

            <EmailEditor uid={paymentData.message_draft_url}/>

            {/* <Text size='lg' className='font-bold mt-10 w-full underline' ta="center">Message log</Text>

            <div className='flex justify-center mt-4'>
                <Timeline bulletSize={30} lineWidth={2}>
                    <Timeline.Item bullet={<IconMailbox size={20} />} title="I'm missing the invoice.">
                        <Text c="dimmed" size="xs" mt={4}>2 hours ago; from: bob@facebo.com; to: billy@goog.com</Text>
                        <Text size="sm">You&apos;ve created new branch <Text variant="link" component="span" inherit>fix-notifications</Text> from master</Text>
                    </Timeline.Item>

                    <Timeline.Item bullet={<IconSend size={20} />} title="I'll send it to you.">
                    <Text c="dimmed" size="xs" mt={4}>52 minutes ago; from: billy@goog.com; to: bob@facebo.com</Text>
                    <Spoiler maxHeight={40} showLabel="Show more" hideLabel="Hide">                
                    <Text size="sm">Hi,</Text>
                    <Text size="sm">Yesterday I was shocked.</Text>
                    <Text size="sm">Good job.</Text>
                    <Text size="sm">Best,</Text>
                    <Text size="sm">Bob</Text>
                    </Spoiler>
                    </Timeline.Item>
                </Timeline>
            </div> */}

        </div>
    )
}
