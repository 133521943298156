import React from "react"
import { useState } from "react"
import { Center, Tooltip, UnstyledButton, Stack } from "@mantine/core"
import {
  Icon,
  SquaresFour,
  EnvelopeOpen,
  Invoice,
  HandCoins,
  Coins,
} from "@phosphor-icons/react"

import { NavButton } from "./NavButton"
import { localLogOut } from "../store/slices/authSlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

interface NavLinkProps {
  icon: Icon
  label: string
  url: string
  id: number
}

export interface NavbarProps {
  /** Array of props describing the underlying nav buttons and the pages they link to */
  data: Array<NavLinkProps>
}

/** Global application navbar */
export const Navbar = ({ data }: NavbarProps) => {
  const [active, setActive] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const links = data.map((link, index) => (
    <NavButton
      key={link.id}
      id={link.id}
      Icon={link.icon}
      label={link.label}
      selected={index === active}
      onClick={() => {
        setActive(index)
        navigate(link.url)
      }}
    />
  ))

  return (
    <nav className="flex flex-col align-center justify-start gap-2 px-6 w-24 h-full border-r-[1px] border-r-neutral-100">
      <Stack justify="center" gap={0}>
        {links}
      </Stack>
    </nav>
  )
}
